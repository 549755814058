import {
  ApolloError,
  ApolloQueryResult,
  NetworkStatus,
  useQuery,
} from '@apollo/client';
import { useSessionContext } from 'contexts/session';
import { omit, uniqBy } from 'lodash';
import { useUser } from 'hooks/use-user.hook';
import gql from 'graphql-tag';

export interface MappedContractSubscriberRequirement {
  contractVersionId: number;
  displayName: string;
  internalName: string;
  contractType: string;
  fileUrl: string;
  scrollRequired: boolean;
  signatureType: string;
  contractVersionLanguage?: {
    id: number;
    fileUrl: string;
  };
  stateAbbreviation?: string;
  status: string;
  utilityAccountId: number;
}

interface HookReturnType {
  data?: MappedContractSubscriberRequirement[];
  loading: boolean;
  error?: ApolloError;
  refetch: (
    variables?:
      | Partial<Exact<{ userId: number; languageShortCode: string }>>
      | undefined
  ) => Promise<ApolloQueryResult<GetContractRequirementsQuery>>;
}

export const useContractRequirements = (): HookReturnType => {
  const { userId } = useSessionContext();
  const { data: user } = useUser();
  const languageShortCode: string = user?.languageShortCode || 'en';

  const { data, loading, error, refetch } = useQuery<
    GetContractRequirementsQuery,
    GetContractRequirementsQueryVariables
  >(GET_CONTRACT_REQUIREMENTS, {
    variables: {
      userId,
      languageShortCode,
    },
  });
  let allRequirements = data?.user?.accounts
    ?.filter(a => a.status === 'active')
    .map(a =>
      a.customPlans
        .filter(p => p.active)
        .filter(
          // Hide requirements for plans that are enrolled since that generally means
          // that contracts were signed offline.
          p => !p.vnmProductSubscriptions?.find(ps => ps?.status === 'active')
        )
        .map(p => {
          return p.utilityAccounts
            .map(ua => {
              return ua.subscriberContractRequirement.dependencies.flatMap(
                d => {
                  if (d.__typename === 'ContractSubscriberRequirement') {
                    return [
                      {
                        ...omit(d.contract, ['id', 'currentVersion', 'state']),
                        ...omit(d.contract.currentVersion!, ['id']),
                        contractVersionId: d.contract.currentVersion!.id,
                        stateAbbreviation: d.contract.state?.abbreviation,
                        status: d.status,
                        utilityAccountId: ua.id,
                      },
                    ];
                  } else {
                    return [];
                  }
                }
              );
            })
            .flat();
        })
        .flat()
    )
    ?.flat();

  if (allRequirements) {
    allRequirements = uniqBy(allRequirements, c => c.contractVersionId);
  }

  return {
    data: allRequirements,
    loading,
    error,
    refetch,
  };
};

export const GET_CONTRACT_REQUIREMENTS = gql`
  query getContractRequirements($userId: Int!, $languageShortCode: String!) {
    user(id: $userId) {
      id
      accounts {
        id
        status
        customPlans {
          id
          active
          vnmProductSubscriptions {
            status
          }
          utilityAccounts {
            id
            subscriberContractRequirement {
              dependencies {
                __typename
                status
                ... on ContractSubscriberRequirement {
                  contract {
                    id
                    displayName
                    internalName
                    contractType
                    currentVersion {
                      id
                      fileUrl
                      scrollRequired
                      signatureType
                      contractVersionLanguage(
                        languageShortCode: $languageShortCode
                      ) {
                        id
                        fileUrl
                      }
                    }
                    state {
                      id
                      abbreviation
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getContractRequirementsMock: GetContractRequirementsQuery = {
  user: {
    id: 1,
    accounts: [
      {
        id: 1,
        status: 'active',
        customPlans: [
          {
            id: 1,
            vnmProductSubscriptions: [],
            utilityAccounts: [
              {
                id: 7,
                subscriberContractRequirement: {
                  dependencies: [
                    {
                      __typename: 'ContractSubscriberRequirement',
                      status: 'pending',
                      contract: {
                        id: 1,
                        displayName: 'Test',
                        internalName: 'test',
                        contractType: 'vnm',
                        currentVersion: {
                          id: 100,
                          fileUrl: 'contract.com',
                          scrollRequired: false,
                          signatureType: 'agreement',
                          contractVersionLanguage: {
                            id: 100,
                            fileUrl: 'contract.com',
                          },
                        },
                        state: null,
                      },
                    },
                  ],
                },
              },
            ],
            active: true,
          },
        ],
      },
    ],
  },
};

export const useContractRequirementsMock: HookReturnType = {
  data: [],
  loading: false,
  error: undefined,
  refetch: () =>
    Promise.resolve({
      data: getContractRequirementsMock,
      loading: false,
      error: undefined,
      networkStatus: NetworkStatus.ready,
    }),
};
