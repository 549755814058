import { Routes } from './routes.typings';

interface Props {
  accountId?: number | string;
  contractVersionId?: string | number;
  projectId?: number | string;
  statementId?: number | string;
  planId?: number | string;
  source?: number | string;
  utilityCredentialId?: number | string;
  utilityAccountId?: number | string;
  paymentMethodId?: number | string;
}

// N.B.: the route name (e.g. currentNotifications) gets passed to segment on track pages
// Confirm with data/product before renaming
export const routes = ({
  contractVersionId = ':contractVersionId',
  projectId = ':projectId',
  statementId = ':statementId',
  planId = ':planId',
  source = ':source',
  utilityCredentialId = ':utilityCredentialId',
  utilityAccountId = ':utilityAccountId',
  paymentMethodId = ':paymentMethodId',
}: Props = {}): Routes => {
  const home = '/';
  const notifications = '/notifications';

  const currentNotifications = '/notifications/current';
  const pastNotifications = '/notifications/past';

  const settings = '/settings';
  const profile = '/profile'; // TODO: Remove in DASH-2250. Needed for now to redirect to Settings

  const contracts = '/settings/contracts';
  const personalizedContract = `${contracts}/${contractVersionId}/personalized/${utilityAccountId}`;

  const accountDetails = '/settings/account-details';
  const accountNickname = `${accountDetails}/nickname`;

  const plans = '/settings/plans';
  const planNickname = `/settings/plans/nickname/${planId}`;
  const activePlans = `${plans}/active`;
  const inactivePlans = `${plans}/inactive`;

  const checkLmiEligibility = '/settings/check-lmi-eligibility';

  const membership = '/settings/membership';
  const nickname = `/settings/membership/nickname/${planId}`;
  const personalInformation = '/settings/personal-information';
  const languagePreference = '/settings/language-preference';
  const payment = '/settings/payment-methods';
  const paymentSettings = `${payment}/settings`;
  const paymentWallet = `${payment}/wallet`;
  const paymentMethod = `${payment}/${paymentMethodId}`;
  const paymentMethodUpdate = `${payment}/update/${planId}`;

  const name = `${personalInformation}/name`;
  const editLogin = `${personalInformation}/login`;
  const smsPreference = `${personalInformation}/sms-preference`;
  const utilitySync = '/settings/utility-sync';

  // Note that this URI is in an Ameren registry and will need to be updated there if changed. Ameren does not support an id in this route.
  const utilitySyncEdit = '/settings/utility-sync/credentials';
  const utilitySyncCredential = `/settings/utility-sync/credentials/${utilityCredentialId}`;
  const switchHome = '/settings/switch-home';

  const moving = '/settings/moving';
  const movingSelectAccount = `${moving}/select-account`;
  const movingAddAccountDetails = `${moving}/add-account-details`;
  const movingAddUtilityServiceDetails = `${moving}/add-utility-service-details`;
  const movingSetUpService = `${moving}/set-up-service`;
  const movingGetSetupReminder = `${moving}/get-setup-reminder`;
  const movingAddOnlineAccount = `${moving}/add-online-account`;
  const movingAddPaymentMethod = `${moving}/add-payment-method`;
  const movingGetOnlineSetupReminder = `${moving}/get-online-setup-reminder`;
  const movingViewConfirmation = `${moving}/view-confirmation`;
  const movingReviewNewHome = `${moving}/review-new-home`;
  const movingViewSuccess = `${moving}/view-success`;

  const referrals = '/settings/referrals';

  // DASHBOARD ROUTES
  const dashboard = '/dashboard';
  const dashboardPlan = `${dashboard}/${planId}`;
  const usage = `${dashboard}/${planId}/usage`;
  const energyAnalysis = `${usage}/energy-analysis`;
  const energyDetails = `${usage}/energy-sources/${source}`;
  const impact = `${usage}/impact`;
  const projects = `${usage}/projects/${projectId}`;
  const recs = `${usage}/recs`;

  const solar = `${dashboard}/${planId}/solar`;

  const billing = `${dashboard}/${planId}/billing`;
  const statements = `${billing}/statements`;
  const statement = `${billing}/statements/${statementId}`;
  const statementDelayPayment = `${billing}/statements/${statementId}/delay-payment`;

  const advocacy = `/advocacy/${utilityAccountId}`;
  const advocacyAddress = `${advocacy}/address`;
  const advocacyMessage = `${advocacy}/message`;
  const advocacySuccess = `${advocacy}/success`;

  const legacyBaseRoutes = ['/advocacy', '/billing', '/solar', '/usage'];

  return {
    accountDetails,
    accountNickname,
    advocacy,
    advocacyAddress,
    advocacyMessage,
    advocacySuccess,
    billing,
    checkLmiEligibility,
    contracts,
    dashboard,
    dashboardPlan,
    editLogin,
    energyAnalysis,
    energyDetails,
    home,
    impact,
    legacyBaseRoutes,
    membership,
    moving,
    movingAddAccountDetails,
    movingAddOnlineAccount,
    movingAddPaymentMethod,
    movingAddUtilityServiceDetails,
    movingSelectAccount,
    movingReviewNewHome,
    movingViewSuccess,
    movingSetUpService,
    movingViewConfirmation,
    movingGetSetupReminder,
    movingGetOnlineSetupReminder,
    name,
    nickname,
    payment,
    paymentSettings,
    paymentWallet,
    paymentMethod,
    paymentMethodUpdate,
    personalInformation,
    personalizedContract,
    languagePreference,
    planNickname,
    plans,
    activePlans,
    inactivePlans,
    notifications,
    pastNotifications,
    currentNotifications,
    recs,
    settings,
    solar,
    statement,
    statements,
    statementDelayPayment,
    usage,
    utilitySync,
    utilitySyncCredential,
    utilitySyncEdit,
    profile,
    projects,
    switchHome,
    referrals,
    smsPreference,
  };
};
