import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useAccountContext } from 'contexts/account';
import {
  filterAndSortCurrentNotifications,
  filterAndSortDashboardNotifications,
  filterAndSortPastNotifications,
  priorityTypes,
} from 'domain/notifications';
import { useContractRequirements } from './use-contract-requirements.hook';

export type Notifications =
  GetAccountNotificationsQuery['account']['notifications'];

export const useAccountNotifications = () => {
  const { selectedAccountId } = useAccountContext();
  const { data, error, loading, refetch } = useQuery<
    GetAccountNotificationsQuery,
    GetAccountNotificationsQueryVariables
  >(GET_ACCOUNT_NOTIFICATIONS, {
    variables: { id: selectedAccountId },
  });

  const {
    data: requirements,
    error: contractError,
    loading: contractLoading,
  } = useContractRequirements();

  let result;
  if (data) {
    // filter any notifications that have been made inactive/dismissed since intitial query
    const notifications = data.account.notifications
      .filter(note => note.active)
      .filter(
        note =>
          note.notificationKey !== 'sign_state_contracts' ||
          !!requirements?.length
      );

    result = {
      currentNotifications: filterAndSortCurrentNotifications(notifications),
      dashboardNotifications:
        filterAndSortDashboardNotifications(notifications),
      pastNotifications: filterAndSortPastNotifications(notifications),
    };
  }
  return {
    data: result,
    error: error ?? contractError,
    loading: loading ?? contractLoading,
    refetch,
  };
};

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationData on Notification {
    id
    actionType
    active
    createdAt
    displayValues
    notificationKey
    priorityType
    targetId
    read
    notifiableId
    notifiablePlanId
  }
`;

export const GET_ACCOUNT_NOTIFICATIONS = gql`
  query GetAccountNotifications($id: Int!) {
    account(id: $id) {
      id
      notifications {
        ...NotificationData
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const currentNotification = {
  actionType: 'no_action',
  active: true,
  createdAt: '2021-01-10',
  displayValues: {
    buttonText: 'click me',
    message: 'Look at this!',
    title: 'A notification',
  },
  id: 2,
  notificationKey: 'my_key',
  priorityType: priorityTypes.update,
  read: false,
  targetId: 1,
  notifiableId: 2,
  notifiablePlanId: 1,
};

export const pastNotification = {
  actionType: 'no_action',
  active: true,
  createdAt: '2021-02-02',
  displayValues: {
    buttonText: 'click at me, bro',
    message: 'Hold my beer!',
    title: 'A past notification',
  },
  id: 3,
  notificationKey: 'my_key',
  priorityType: priorityTypes.update,
  read: true,
  targetId: 1,
  notifiableId: 2,
  notifiablePlanId: 1,
};

export const dashboardNotification = {
  actionType: 'no_action',
  active: true,
  createdAt: '2021-02-02',
  displayValues: {
    buttonText: 'click at me, bro',
    message: 'Hold my hat!',
    title: 'A dashboard notification',
  },
  id: 4,
  notificationKey: 'my_key',
  priorityType: priorityTypes.low,
  read: true,
  targetId: 1,
  notifiableId: 2,
  notifiablePlanId: 1,
};

export const getAccountNotificationsMock = {
  account: {
    id: 1,
    notifications: [
      { __typename: 'Notification', ...currentNotification },
      { __typename: 'Notification', ...pastNotification },
      { __typename: 'Notification', ...dashboardNotification },
    ],
  },
};

export const useAccountNotificationsMock = {
  data: {
    currentNotifications: [currentNotification],
    pastNotifications: [pastNotification, dashboardNotification],
    dashboardNotifications: [dashboardNotification],
  },
  loading: false,
};
