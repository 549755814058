import gql from 'graphql-tag';

export const USER_ACCOUNTS = gql`
  query UserAccounts($userId: Int!) {
    user(id: $userId) {
      id
      firstName
      languageShortCode
      accounts {
        id
        createdAt
        name
        status
        accountNumber
        customPlans {
          utilityAccounts {
            id
          }
        }
      }
      zipCode {
        state {
          name
        }
      }
    }
  }
`;

export const firstAccount = {
  id: 1,
  name: 'Account 1',
  status: 'deleted',
  createdAt: '2019-01-01T04:00:00.000Z',
  accountNumber: 1111,
  customPlans: [{ utilityAccounts: [{ id: 1 }] }],
};

export const secondAccount = {
  id: 2,
  createdAt: '2019-01-11T04:00:00.000Z',
  name: 'My account',
  status: 'active',
  accountNumber: 2222,
  customPlans: [{ utilityAccounts: [{ id: 2 }] }],
};

export const thirdAccount = {
  id: 3,
  name: 'Account 3',
  status: 'active',
  createdAt: '2022-03-03T04:00:00.000Z',
  accountNumber: 3333,
  customPlans: [{ utilityAccounts: [{ id: 3 }] }],
};

export const accountIdMock = 2;

export const accountMock = {
  selectedAccountId: accountIdMock,
  selectedAccount: secondAccount,
  accounts: [secondAccount, firstAccount, thirdAccount],
};

export const userAccountsMock = {
  user: {
    id: 10,
    firstName: 'Cornelius',
    accounts: [secondAccount, firstAccount, thirdAccount],
  },
};
